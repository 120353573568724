import * as React from "react";

const ervaring = [
  {
    company: "Marja Bron Communicatie",
    period: "2017",
    position: "Senior Marketing- en Communicatieprofessional",
    description:
      "Versterking van management of communicatieteam. Projectmatig of op advies- of interimbasis.",
    footer:
      "Communicatiestrategie, merkpositionering, conceptontwikkeling, contentstrategie, sponsoring en branding.",
  },
  {
    company: "Team communicatie",
    period: "2011 - 2017",
    position: "Oprichter en mede-eigenaar",
    description:
      "TEAM ontwikkelt communicatiestrategieën en creatieve concepten om merken krachtig te positioneren.",
    footer:
      "Strategie, merkenarchitectuur, positionering, conceptontwikkeling, branding en projectmanagement.",
  },
  {
    company: "Voslibert Communicatie",
    period: "2004 - 2010",
    position: "Accountmanager",
    description:
      "Verantwoordelijk voor relatiebeheer, conceptbegeleiding tot uitvoering.",
    footer:
      "Projectmanagent, ontwikkeling online/offline communicatiemiddelen, vertaling van strategie naar uitvoering.",
  },
];

class Ervaring extends React.Component {
  render() {
    return (
      <section id="ervaring" className="bg-sand">
        <div className="max-w-7xl mx-auto flex flex-col gap-10 sm:gap-14 p-10 sm:py-24">
          <h2 className="text-2xl sm:text-4xl sm:mx-auto">Ervaring</h2>
          <div className="grid sm:grid-cols-3 gap-8 sm:gap-4">
            {ervaring.map((item, index) => (
              <div className="flex flex-col gap-3" key={index}>
                <div>
                  <h3 className="text-lg uppercase font-medium text-pacific">
                    {item.company}
                  </h3>
                  <h4 className="font-medium">{item.period}</h4>
                  {item.position && <p>{item.position}</p>}
                </div>
                {item.description && <p>{item.description}</p>}
                {item.tags && <p>{item.tags}</p>}
                {item.footer && <p className="italic">{item.footer}</p>}
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Ervaring;
