import * as React from "react";
import logos from "../data/logos";

class Opdrachtgevers extends React.Component {
  render() {
    return (
      <section
        id="opdrachtgevers"
        className="max-w-7xl mx-auto sm:text-center flex flex-col gap-10 sm:gap-14 p-10 sm:py-24 bg-white"
      >
        <h2 className="text-2xl sm:text-4xl">Opdrachtgevers</h2>
        <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-4 gap-14">
          {logos.map((logo, index) => (
            <div className="flex items-center justify-center" key={index}>
              <img src={logo} className="max-h-16" alt="" />
            </div>
          ))}
        </div>
      </section>
    );
  }
}

export default Opdrachtgevers;
