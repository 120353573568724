import * as React from "react";
import marja from "../images/Marja-Bron-teamspeler-in-communicatie.png";

const MaakKennis = () => {
  return (
    <section id="maakkennis" className="bg-white">
      <div className="max-w-7xl mx-auto grid sm:grid-cols-2 gap-10 p-10 sm:py-24">
        <img
          src={marja}
          alt="Marja Bron Communicatie"
          className="rounded-lg shadow-lg w-full max-w-[300px] sm:max-w-[400px] mx-auto"
        />

        <div className="flex flex-col gap-2 sm:gap-6 leading-8">
          <h2 className="text-2xl sm:text-4xl mb-4">Kennismaken?</h2>
          <p className="text-pacific font-medium text-lg sm:text-xl leading-7 mb-2">
            Graag kom ik je team versterken, op tijdelijke basis of voor de
            langere termijn. Initiatiefrijk, besluitvaardig en daadkrachtig.
          </p>

          <p>
            Mijn naam is Marja Bron. Ik leer je graag goed kennen en vind elkaar
            ontmoeten de belangrijkste start van onze samenwerking. Vertel me
            wat je drijfveren zijn en waar ik je mee kan helpen. Ik ga
            vervolgens vol energie aan de slag met jouw communicatievraagstuk.
            Ik ben van waarde omdat ik kennis en ervaring combineer met
            daadkracht en enthousiasme.
          </p>

          <p>
            Je kunt bij mij terecht voor opdrachten van strategie en concept tot
            uitvoering. Ik heb twintig jaar ervaring als communicatieadviseur en
            projectmanager bij communicatiebureaus. Ook deed ik ervaring op als
            communicatiemedewerker bij verschillende (non)profit-organisaties,
            in verschillende branches.
          </p>

          <p>Kennismaken? Laten we dat doen.</p>
          <p className="text-lg text-pacific font-medium">
            advies <span className="inline-block mx-2">&bull;</span> projecten{" "}
            <span className="inline-block mx-2">&bull;</span> interim
          </p>
        </div>
      </div>
    </section>
  );
};

export default MaakKennis;
