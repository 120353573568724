import * as React from "react";
import Hero from "../components/hero";
import MaakKennis from "../components/maak-kennis";
import Footer from "../components/footer";
import Ervaring from "../components/ervaring";
import Opdrachtgevers from "../components/opdrachtgevers";
import drops from "../images/drops.jpg";
import { Helmet } from "react-helmet";

// markup
const IndexPage = () => {
  return (
    <main className="font-light overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marja Bron Communicatie</title>
        <link rel="canonical" href="https://www.marjabron.nl" />
      </Helmet>

      <Hero />

      <div className="relative z-20">
        <MaakKennis />

        <Ervaring />

        <Opdrachtgevers />

        {/* sfeer */}
        <div
          className="relative min-h-[300px] bg-fixed bg-auto sm:bg-cover flex flex-col gap-14 text-white"
          style={{ backgroundImage: `url(${drops})` }}
        ></div>

        <Footer />
      </div>
    </main>
  );
};

export default IndexPage;
