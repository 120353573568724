import interzorgGroep from "../images/clients/2016-06-21-Woordbeeldlogo-IG.png";
import atWork from "../images/clients/AtWork_logo_fc-130x40.png";
import bedum from "../images/clients/BEDUM-101x60.png";
import biblionet from "../images/clients/Biblionet-Drenthe-CMYK_CROP.png";
import noorderBasisSchool from "../images/clients/default-logo-119x57.png";
import gemeenteAaHunze from "../images/clients/Gemeente_AAHunze_logo-178x60.png";
import gemeenteNoordenveld from "../images/clients/gemeente_noordenveld_logo-194x60.png";
import kwadrantGroep from "../images/clients/KwadrantGroep-1.png";
import lindeCollege from "../images/clients/Linde-College-113x75.png";
import kredietbank from "../images/clients/logo_kredietbank_klein.png";
import noorderMarke from "../images/clients/LOGO_NOORDERMARKE_LANDSCAPE_RGB_MARGE_800px-202x70.png";
import stichtingLezenSchrijven from "../images/clients/logo_stichtinglezenschrijven_klein.png";
import syncasso from "../images/clients/logo_syncasso_klein.png";
import unive from "../images/clients/LOGO_UNIVE-1-160x60.png";
import winkelman from "../images/clients/LOGO_WInkelman_Landscape_RGB-200x60.png";
import deGildeGroep from "../images/clients/Logo-De-Gilde-Groep_2019_fc-163x61.png";
import vsco from "../images/clients/LOGO_VCSO_RGB_POS.png";
import dbg from "../images/clients/Originele-Logo_DBG_PMS.png";
import deLibrije from "../images/clients/PCBS-De-Librije-202x48.png";
import probaat from "../images/clients/probaat-175x52.png";
import provincieDrenthe from "../images/clients/provincie-drenthe_rgb-202x30.png";
import hzpc from "../images/clients/HZPC.png";
import deTrans from "../images/clients/De-Trans.png";
import engineering from "../images/clients/logo-engineering.png";
import rgfStaffing from "../images/clients/RGF-Staffing.png";
import skaal from "../images/clients/Skaal_logo_RGB.jpg";
import technicumImpact from "../images/clients/technicum_impact.png";
import zorgVeiligheidsHuisGroningen from "../images/clients/Zorg-en-veiligheidshuis-Groningen.jpg";
import haitsmaLogo from "../images/clients/haitsma-logo.png";
import intecmaLogo from "../images/clients/intecma-logo.png";
import zelfmeldersLogo from "../images/clients/zelfmelders-logo.jpg";
import tamsmaLogo from "../images/clients/tamsma-logo.png";
import metisLogo from "../images/clients/metisLogo.png";
import ggdFryslanLogo from "../images/clients/ggdFryslanLogo.png";
import veiligheidsRegioLogo from "../images/clients/veiligheidsregioFryslanLogo.png";
import friesePreventieAanpakLogo from "../images/clients/friesePreventieAanpakLogo.png";

const logos = [
  // 1
  noorderBasisSchool,
  vsco,
  lindeCollege,
  noorderMarke,
  // 2
  rgfStaffing,
  technicumImpact,
  engineering,
  deGildeGroep,
  // 3
  provincieDrenthe,
  bedum,
  gemeenteAaHunze,
  gemeenteNoordenveld,
  // 4
  kwadrantGroep,
  deTrans,
  interzorgGroep,
  zorgVeiligheidsHuisGroningen,
  // 5
  ggdFryslanLogo,
  veiligheidsRegioLogo,
  friesePreventieAanpakLogo,
  zelfmeldersLogo,
  // 6
  hzpc,
  unive,
  deLibrije,
  winkelman,
  // 7
  haitsmaLogo,
  intecmaLogo,
  metisLogo,
  tamsmaLogo,
  // 8
  probaat,
  dbg,
  skaal,
  atWork,
  // 9
  biblionet,
  syncasso,
  kredietbank,
  stichtingLezenSchrijven,
];

export default logos;
